import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { faFacebookF, faPinterestP, faTwitter } from '@fortawesome/free-brands-svg-icons';


export default function Footer() {
    const handleScrollToSection = (sectionId) => {
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    }
    return (
        <>
            <footer className="aizelle_footer">
                <div className="aizelle_footer_top sub_para">
                    <div className="container">
                        <div className="row align-items-start g-md-5 g-lg-2 g-4">
                            <div className="col-lg-4 col-md-12">
                                <Link to={"/"}>
                                    <img src="assets/images/logo/logo.svg" alt="aizelle" className="img-fluid" />
                                </Link>
                                <p className="sub_para mt-3">
                                    Discover a tech-driven algo trading platform that can supercharge your trading journey. Take fast and accurate trades with pre-built equity, option buying & selling algo strategies.
                                </p>
                            </div>
                            <div className="col-lg-2 offset-lg-2 offset-md-0 col-md-7 col-6">
                                <h6 className="heading_h6 mb-lg-4  mb-3">Services</h6>

                                <ul className="list-unstyled mb-0">
                                    <li className="mb-lg-3 mb-2">
                                        <Link to="/" className="text-decoration-none text-dark  sub_para">Home</Link>
                                    </li>
                                    <li className="mb-lg-3 mb-2">
                                        <Link href="" className="text-decoration-none text-dark  sub_para" onClick={() => handleScrollToSection("about")}>About us</Link>
                                    </li>
                                    <li className="mb-lg-3 mb-2">
                                        <Link href="/about" className="text-decoration-none text-dark  sub_para" onClick={() => handleScrollToSection("whyus")}>Why us</Link>
                                    </li>
                                    <li className="mb-lg-3 mb-2">
                                        <Link href="#!" className="text-decoration-none text-dark  sub_para"
                                            onClick={() => handleScrollToSection("faq")}
                                        >Faq</Link>
                                    </li>
                                    <li className="mb-lg-3 mb-2">
                                        <Link href="#!" className="text-decoration-none text-dark  sub_para" onClick={() => handleScrollToSection("contact")} >Contact</Link>
                                    </li>
                                </ul>
                            </div>
                           
                            <div className="col-xl-3 col-lg-3 col-md-5 offset-lg-1 offset-md-0">
                                <h6 className="heading_h6 mb-lg-4 mb-3">Contact Us</h6>

                              
                                <p className="d-flex gap-2 align-items-center">
                                    <div className="number_padding text-white rounded-circle d-flex align-items-start justify-content-center">
                                        <img src="assets/images/footer/email.svg" alt="" />
                                    </div>
                                    <a className="text-decoration-none text-dark" href="mailto:info@aizelle.com">
                                        info@aizelle.com
                                    </a>
                                </p>
                                <p className="d-flex gap-2 align-items-center">
                                    <div className="number_padding text-white rounded-circle d-flex align-items-start justify-content-center">
                                        <img src="assets/images/footer/address.svg" alt="" />
                                    </div>
                                    <Link className="text-decoration-none text-dark">

                                        21 king loram street loram ,ipsam 3000,dubai
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aizelle_footer_bottom overflow-hidden">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 border_hr my-4 w-100 h-100"></div>
                            <div className="col-xl-4">
                                <ul className="list-unstyled row row-cols-1  g-3 g-xl-1 text-center text-xl-start mb-0">
                                   
                                    <li>
                                        <Link to="/privacypolicy" className="text-dark text-decoration-none sub_para">Privacy Policy</Link>
                                    </li>
                                   
                                </ul>
                            </div>
                            <div className="col-xl-4 mt-xl-0 mt-3">
                                <p className="sub_para text-center mb-0">
                                    © 2024 Aizelle. All Rights Reserved
                                </p>
                            </div>
                            <div className="col-xl-4 mt-xl-0 mt-3">
                                <ul className="text-decoration-none d-flex gap-2 list-unstyled justify-content-xl-end justify-content-center mb-0">

                                    <li>
                                        <Link className="color_white fotter_social_icon">
                                            <FontAwesomeIcon icon={faFacebookF} style={{ color: "#000000" }} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="color_white fotter_social_icon">
                                            <FontAwesomeIcon icon={faTwitter} style={{ color: "#000000" }} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="color_white fotter_social_icon">
                                            <FontAwesomeIcon icon={faPinterestP} style={{ color: "#000000" }} />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const handleScrollToSection = (sectionId) => {
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  return (
    <>
      <div className="small_info">
        <div className="container-fluid">
          <div className="d-flex gap-4 justify-content-md-start justify-content-between px-lg-5 px-md-4 px-0">
            <div>
              <img src="assets/images/home/email.svg" alt="" className="me-2" />
              <a className="text-decoration-none" href="mailto:info@aizelle.org">info@aizelle.org</a>
            </div>
            {/* <div>
              <img
                src="assets/images/home/phonecall.svg"
                alt=""
                className="me-2"
              />
              <Link className="text-decoration-none">+91 00000000</Link>
            </div> */}
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-xl bg-body-tertiary aizelle_navbar w-100 bg-white overflow-hidden">
        <div className="container">
          <Link
            to="/"
            className="navbar-brand d-flex align-items-center nav_width"
          >
            <span className="brand-text aizelle_logo">
              <img
                src="assets/images/logo/logo.svg"
                alt="aizelle"
                className="img-fluid"
              />
            </span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-xl-center ">
              <li className="nav-item nav_menu">
                <Link className="nav-link menu_padding " href="#home">
                  HOME
                </Link>
              </li>
              <li className="nav-item nav_menu">
                <Link
                  to=""
                  className="nav-link menu_padding "
                  onClick={() => handleScrollToSection("about")}
                >
                  ABOUT US
                </Link>
              </li>
              {/* <li className="nav-item dropdown nav_menu">
                                <Link to="" className="nav-link menu_padding dropwown_icon ">
                                    ALGO TRADING
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="" className="dropdown-item">Option 1</Link></li>
                                    <li><Link to="" className="dropdown-item">Option 2</Link></li>
                                </ul>
                            </li> */}
              <li className="nav-item nav_menu">
                <Link
                  to=""
                  className="nav-link menu_padding "
                  onClick={() => handleScrollToSection("whyus")}
                >
                  WHY US
                </Link>
              </li>
              {/* <li className="nav-item nav_menu dropdown">
                                <Link to="" className="nav-link menu_padding dropwown_icon ">
                                    ALGO STRATEGIES
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="" className="dropdown-item">Option 1</Link></li>
                                    <li><Link to="" className="dropdown-item">Option 2</Link></li>
                                </ul>
                            </li> */}
              <li className="nav-item nav_menu">
                <Link
                  to=""
                  className="nav-link menu_padding"
                  onClick={() => handleScrollToSection("faq")}
                >
                  FAQ
                </Link>
              </li>
              <li className="nav-item nav_menu">
                <Link
                  to=""
                  className="nav-link menu_padding"
                  onClick={() => handleScrollToSection("contact")}
                >
                  CONTACT
                </Link>
              </li>
              <li className="nav-item nav_menu login">
                <Link
                  to="https://member.aizelle.org/"
                  className="nav-link menu_padding btn aizelle_btn py-2"
                >
                  LOGIN
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Privacypolicy from './pages/Privacypolicy';
import TradeHistory from './pages/TradeHistory';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" index element={<Home />} />
      <Route path='/trade_history' element={<TradeHistory/>}/>
      <Route path="/privacypolicy" element={<Privacypolicy />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
